//======================================================================================================
// Bloc Content (textual content) - alert, paragraph, question-answer, quote
//======================================================================================================
.bloc-content {
    @include flex($alignItems: center);
    padding: 30px;
    margin: 30px 0;

    &__container {
        flex: 1;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight;
        font-size: $font-size--text-medium ;
        text-transform: uppercase;
    }

    &__content {
        margin-top: 5px;
    }

    &--paragraph,
    &--question_answer,
    &--alert {

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        a {
            display: inline-block;
            text-decoration: underline;
            color: $color-link;

            @extend %external_link;
            &::after {
                @include relative($top: 5px);
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: $color-link--hover;

                &::after {
                    background-color: $color-link--hover;
                }
            }
        }

        ul, ol {
            line-height: 1.75;
            padding-left: 20px;
        }

        ul {
            li {
                position: relative;

                &::before {
                    content: "";
                    @include absolute($left: -15px, $top: 9px);
                    display: flex;
                    @include size(8px);
                    background-color: $bullet-level-1;
                    border-radius: $border-radius--round;
                }

                ul {
                    li {
                        &::before {
                            background-color: $bullet-level-2;
                        }

                        ul {
                            li {
                                &::before {
                                    background-color: $bullet-level-3;
                                }
                            }
                        }
                    }
                }
            }
        }

        // For site map
        ul.nav-grandchild {
            li::before {
                color: $color-gray;
            }
        }

        ol {
            list-style-type: decimal;
        }

        & > ul, & > ol,
        & .bloc-content__text > ul, & .bloc-content__text > ol,
        & .accordion__content--question > ul, & .accordion__content--question > ol {
            margin: 20px 0;
        }

        &.border {
            padding: 15px;
            border-left: 15px solid;

            > svg {
                display: none;
            }

            &.primary {
                border-color: $b-primary-bg;
            }

            &.secondary {
                border-color: $b-secondary-bg;
            }

            &.neutral {
                border-color: $b-neutral-bg;
            }
        }

        &.background {
            @include flex($alignItems: center);
            padding: 30px;
            border-radius: $border-radius;

            > svg {
                position: relative;
                @include size(80px);
                margin-right: 20px;
                margin-left: -15px;
            }

            &.primary {
                background-color: $b-primary-bg;
                color: $b-primary-color;

                > svg {
                    fill: $b-primary-color;
                }

                ul {
                    li {
                        &::before {
                            background-color: $b-primary-color;
                        }
                    }
                }

                a {
                    color: $b-primary-color;

                    // External link
                    &::after {
                        background-color: $b-primary-color;
                    }
                }
            }

            &.secondary {
                background-color: $b-secondary-bg ;
                color: $b-secondary-color;

                > svg {
                    fill: $b-secondary-color;
                }

                ul {
                    li {
                        &::before {
                            background-color: $b-secondary-color;
                        }
                    }
                }

                a {
                    color: $b-secondary-color;
                    
                    // External link
                    &::after {
                        background-color: $b-secondary-color;
                    }
                }
            }

            &.neutral {
                background-color: $b-neutral-bg;
                color: $b-neutral-color;

                > svg {
                    fill: $b-neutral-color;
                }

                ul {
                    li {
                        &::before {
                            background-color: $b-neutral-color;
                        }
                    }
                }

                a {
                    color: $b-neutral-color;
                    
                    // External link
                    &::after {
                        background-color: $b-neutral-color;
                    }
                }
            }

            .bloc-content {
                &__text {
                    flex: 1;
                }
            }

        }
    }

    &--paragraph,
    &--question_answer {
        display: inherit;
        padding: 0;
    }

    &--alert {
        background-color: $color-flexible-alert;
        border-radius: $border-radius;
        color: $color-white--fixed;

        svg {
            @include size(57px, 54px);
            margin-right: 30px;
            fill: $color-white--fixed;
        }

        a {
            color: $color-white--fixed;

            &[target=_blank]::after {
                background-color: $color-white--fixed;
            }

            &:hover, &:focus {
                color: $color-white--fixed;
            }
        }

        & .bloc-content__content > ul, & .bloc-content__content > ol {
            margin: 20px 0;
        }

        ul {
            li {
                &::before {
                    background-color: $color-white--fixed;
                }

                ul {
                    li {
                        &::before {
                            background-color: $color-white--fixed;
                        }

                        ul {
                            li {
                                &::before {
                                    background-color: $color-white--fixed;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--quote {
        justify-content: center;
        align-items: flex-start;
        padding: 0;

        .bloc-content__container {
            max-width: 890px;
            padding: 10px 60px;
        }

        .bloc-content__content {
            position: relative;
            margin: 0;

            svg {
                content: "";
                position: absolute;
                @include size(24px, 18px);
                fill: $color-main;

                &:first-child {
                    top: 0;
                    left: -44px;
                }

                &:last-child {
                    right: -44px;
                    bottom: 0;
                }
            }

            blockquote {
                padding: 0;
                margin: 0;
                border-left: none;
                font-size: $font-size--5;
                line-height: 1.4;
                text-align: center;
            }

            p {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .bloc-content__author {
            margin-top: 10px;
            font-weight: $font-weight-medium;
            color: $color-text;
            text-align: center;
        }

        .bloc-content__source {
            @include flex($justifyContent: center);
            margin-top: 10px;
            color: $color-link;

            &:hover, &:focus {
                color: $color-link--hover;
                text-decoration: underline;
            }
        }
    }

    &.--background1 {
        background-color: $color-main;
        color: $color-white;
    }

    &.--background2 {
        background-color: $color-second;
        color: $color-white;
    }

    &.--background3 {
        background-color: $color-third;
    }

    &.--border1 {
        padding: 10px 20px;
        border-left: 10px solid $color-main;
    }

    &.--border2 {
        padding: 10px 20px;
        border-left: 10px solid $color-second;
    }

    &.--border3 {
        padding: 10px 20px;
        border-left: 10px solid $color-third;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-content--paragraph.background,
    .bloc-content--question_answer.background {
        padding: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-content {
        padding: 20px;
        margin: 20px 0;

        &--alert {
            flex-direction: column;
            align-items: flex-start;

            svg {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &--paragraph {
            &.background {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &--quote,
        &--paragraph,
        &--question_answer {
            padding: 0;
        }
    }
    
}
