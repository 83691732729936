html {

    // Global
    img, video {
        filter: brightness(1) contrast(1);
        transition: filter $duration $timing
    }

    &[data-theme="dark"] { 

        // Global
        img, video {
            filter: brightness(.8) contrast(1.2);
        }

        // Access42
        #a42-ac {
            background-color: #042B27;
        }

        // Top Post
        .cover {
            &__informations {
                background-color: #264945;
            }
        }

        // Events Card
        .card {
            &__date {
                background-color: $color-bg-dark--fixed;
            }
        }

        // Search
        .searchResult {
            border-color: rgb(54, 96, 91);
        }

        .search-popup {
            background-color: #042B27;
        }

        // Footer
        footer {
            &:before {
                background-image: url($urlSpriteImage + 'theme-icons/fond_page_darkmode.svg')!important;
            }
        }

        //=============
        // Home
        //=============

            // Searchbar
            .searchaccess .searchbar-home .search-container #searchInput {
                background-color: #042B27;
            }

            .searchaccess .searchbar-home__bar .searchResult li {
                border-color: $color-light;
                background-color: #042B27;

                a {
                    background-color: #042B27;

                    &:hover, &:focus {
                        background-color: $color-main--fixed;
                    }
                }

                a, span {
                    color: $color-white--fixed;
                }
            }

            // Posts
            .posts {
                &__item {
                    &:hover {
                        .posts {
                            &__item {
                                &__title {
                                    color: $color-white--fixed!important;
                                }
                            }
                        }
                    }
                }
            }
            

            // Events
            .events {
                &__section {
                    &:before {
                        background-image: url($urlSpriteImage + 'theme-icons/fond_page_darkmode.svg');
                    }
                }
            }

            // Kiosque
            .kiosque {
                &__shape {
                    fill: #042B27;
                }
            }

            // socials
            .socials {
                &__content {
                    background-color: #264945;
                }

                &__list {
                    background-color: $color-main--fixed;
                }
            }
    }
}