.nav-main--burger {
    z-index: inherit;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            @include absolute($bottom: 0, $right: 0);
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 10px;
            @include size(fit-content, 40px);
            margin: 0;
            padding: 10px 20px;
            background-color: $color-third;
            border: 0;
            border-radius: $btn-border-radius;
            transition: all $duration $timing;
            cursor: pointer;

            &__text {
                font-size: toRem(15);
                font-weight: $font-weight-bold; 
                color: $color-dark--fixed;
                text-transform: uppercase;
            }

            svg {
                @include size(20px);
                fill: $color-dark--fixed;
            }

            &:hover, &:focus {
                background-color: $color-white--fixed;
                border-color: $color-white--fixed;

                svg {
                    fill: $color-dark--fixed;
                }
            }

            // Open menu
            &--open {
                position: initial;
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
            }

            // Close menu
            &--close {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100vh);
        background-color: $color-bg-dark--fixed;
        padding: 0;
        transition: {
            property: top, left;
            duration: .5s, $duration;
            timing-function: $timing;
        }

        &::before {
            content: '';
            @include absolute($top: 0, $left: 0, $bottom: 0);
            z-index: 1;
            @include size(calc(50% - 150px), 100%);
            background-color: $color-main--fixed;
            border-radius: 0 30px 30px 0;
        }
    }

    // Section menu (for overflow)
    &__section {
        position: relative;
        z-index: 1;
        overflow-y: auto;
        height: 100%;
        padding: 40px 0 20px 0;
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
    }

    // if Burger menu open
    &.js-open-menu {

        // Burger menu Button
        .nav-main-buttons__menu {

            // Close menu
            &--close {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
            }

            // Open menu
            &--open {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top .7s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 35px;
        @include size(390px, auto);
        margin-top: 110px;
        margin-bottom: 40px;
        padding: 0 45px 0 0;
        box-sizing: content-box;

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;

            &.menu-item-has-children {

                &.js-open-submenu {
                    .menu__link {
                        padding-left: 40px;
                        opacity: 1;
        
                        &:before {
                            opacity: 1;
                            background-color: $color-fourth;
                            width: 30px;
                        }
                    }
                    
                    .submenu {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            padding: 0 0 0 20px;
            color: $color-white--fixed;
            opacity: .7;
            font-size: toRem(22);
            line-height: toRem(25);
            font-weight: $font-weight-bold;
            text-transform: none;
            transition: {
                property: padding-left, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &::before {
                content: '';
                @include absolute($top: 0, $left: 0, $bottom: 0);
                @include size(10px);
                background-color: $color-white--fixed;
                border-radius: 20px;
                margin: auto 0;
                opacity: .7;
                transition: {
                    property: background-color, opacity, width;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                opacity: 1;
                padding-left: 40px;

                &:before {
                    opacity: 1;
                    background-color: $color-fourth;
                    width: 30px;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: block;
        @include absolute($top: 0, $left: 100%, $right: inherit);
        z-index: inherit;
        width: 415px;
        padding: 0 0 0 100px;
        background-color: $color-bg--transparent;
        box-shadow: none;
        opacity: 0;
        pointer-events: none;
        transition: opacity $duration $timing;

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 5px 30px;
            max-width: inherit;
            padding: 0;
        }

        &__item {
            width: 100%;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            padding: 10px 40px 10px 0;
            color: $color-white--fixed;
            font-size: toRem(16);
            line-height: toRem(25);
            font-weight: $font-weight-bold;

            svg {
                @include absolute($top: 0, $right: 0, $bottom: 0);
                @include size(25px);
                margin: auto 0;
                fill: $color-white--fixed;
                background-color: $color-bg--transparent;
                border-radius: 5px;
                opacity: .7;
                transition: {
                    property: background-color, fill, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                svg {
                    opacity: 1;
                    fill: $color-text--fixed;
                    background-color: $color-btn;
                }
            }
        }
    }
}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        &__popup {
            &:before {
                width: calc(50% - 50px);
            }
        }

        // Main menu
        .menu {
            margin-top: 100px;
            padding: 0 40px 0 0;
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    .nav-main--burger.js-open-menu .nav-main-buttons__menu--close {
        &:hover, &:focus {
            background-color: $color-bg--transparent!important;

            svg {
                fill: $color-white--fixed!important;
            }
        }
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        // if Burger menu open
        &.js-open-menu {

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                transition: left $duration $timing;
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);

            // Open menu
            &__menu {

                // Close menu
                &--close { 
                    @include absolute($bottom: 0, $right: 0, $top: 0);
                    @include flex($alignItems: center, $justifyContent: center);
                    @include size(45px);
                    min-height: inherit;
                    margin: 40px calc(5% + 10px) 0 auto;
                    padding: 0;
                    border-radius: $border-radius--round;
                    border: $btn-border-width $btn-border-style $color-white--fixed;
                    background-color: $color-bg--transparent;

                    .nav-main-buttons__menu__text {
                        display: none;
                    }

                    svg {
                        @include size(30px);
                        fill: $color-white--fixed;
                    }
                }
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 0, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(45px);
                margin: 40px auto 0 calc(5% + 10px);
                padding: 0;
                background-color: $color-bg--transparent;
                border: $btn-border-width $btn-border-style $color-white--fixed;
                border-radius: $border-radius--round;
                transition: {
                    property: left;
                    duration: $duration;
                    timing-function: $timing;
                }

                svg {
                    @include size(40px);
                    fill: $color-white--fixed;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;

            &::before {
                content: none;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 100px calc(5% + 10px) 40px;
            overflow: hidden;

            &__item {
                opacity: 1;

                &.menu-item-has-children.js-open-submenu .submenu {
                    left: 0;
                }
            }

            &__link {
                opacity: 1;

                &:before {
                    opacity: 1;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            opacity: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 0 0;
            background-color: $color-main--fixed;
            transition: left $duration $timing;

            &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px calc(5% + 10px) 40px;  
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 132px;
            }

            &__popup::before {
                height: 132px;
            }
        }
    }
}

// 800
@media screen and (max-width: 800px) {

    .nav-main--burger {
        .nav-main-buttons {
            &__menu--close, &__submenu {
                position: fixed;
            }
        }
    }
}