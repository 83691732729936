.home .footer {
    &:before {
        content: "";
        z-index: -1;
        @include absolute($top: -700px, $right: 0, $left: 0);
        height: 1475px;
        background-image: url($urlSpriteImage + 'theme-icons/fond_page.svg');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: auto;
        opacity: .2;
    }
}

.footer {
    position: relative;
    background-color: $color-main--fixed;

    &__container {
        @include flex($wrap: wrap, $alignItems: flex-start);
        gap: 50px;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    &__infos,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: center,$alignItems: flex-start);
        width: 300px;
        gap: 0;
        color: $color-white--fixed;
    }

    &__title {
        color: $color-white--fixed;
        @include font-size(22);
        line-height: toRem(25);
        margin-bottom: 15px;
        font-weight: $font-weight-bold;
        font-family: $font-family--heading;
    }

    &__phone {
        @include flex($alignItems: center);
        color: $color-white--fixed;
        font-weight: $font-weight-bold;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;
        transition: {
            property: background-color, border-color, color, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            color: $color-white--fixed;
            text-decoration: underline;
            text-decoration-color: $color-white--fixed;
        }
    }

    &__buttons {
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        gap: 20px;
        margin-left: 70px;
        width: 260px;
    }

    &__btn {
        @include size(100%, 50px);
        color: $color-text--fixed;
        background-color: $color-third;
        border-color: $color-third;
        margin: 0;
        padding: 0;
        transition: {
            property: background-color, border-color, color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(20px);
            fill: $color-text--fixed;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-white--fixed;
            border-color: $color-white--fixed;
            color: $color-text--fixed;

            svg {
                fill: $color-text--fixed;
            }
        }

        &--labels {
            color: $color-white--fixed;
            background-color: $color-bg--transparent;
            border-color: $color-btn;

            svg {
                fill: $color-white--fixed;
            }

            &:hover, &:focus {
                background-color: $color-btn;
                border-color: $color-btn;
                color: $color-text--fixed;
            
                svg {
                    fill: $color-text--fixed;
                }
            }
        }
    }

    &__menu {
        padding: 30px 0 80px;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
        }

        &__link {
            font-size: toRem(14);
            color: $color-white--fixed;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            transition: {
                property: background-color, border-color, color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                text-decoration-color: $color-white--fixed;
                color: $color-white--fixed;
                text-decoration: underline;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &:before {
            top: 555px;
            height: 1180px;
        }

        &__container {
            gap: 20px;
        }

        &__buttons {
            margin-left: 40px;
            width: 260px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {

        &:before {
            top: 725px;
            height: 790px;
        }

        &__buttons {
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0 0;
            width: unset;
        }

        &__btn {
            padding: 15px 25px;
            width: unset;
        }

        &__menu {
            &__list {
                justify-content: flex-start;
                gap: 20px 40px;
            }
        }
    }
}


// 800
@media screen and (max-width: 800px) {

    .footer {

        &__container {
            flex-direction: column;
            padding-top: 80px;
            gap: 30px;
        }

        &__menu {
            padding-bottom: 120px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    footer {
        &:before {
            display: none;
        }
    }

}
