//======================================================================================================
// A CHANGER SELON THEME ou au besoin
//======================================================================================================

.wrapper {
    #comarquage {
        &.espace-part,
        &.espace-pro,
        &.espace-asso {

            // Button 
            .co-btn.co-btn-outline-primary {
                color: $color-text !important;
                border-color: $color-text;

                &:hover {
                    color: $color-white !important;
                    background-color: $color-text;
                }
            }

            .co-btn.co-btn-primary {
                color: $color-text--fixed !important;

                &:hover, &:focus {
                    color: $color-text !important;
                }
            }

            // Top
            #co-bar {

                // Home button
                .co-home  {
                    svg {
                        fill: $color-text;

                        &:hover {
                            fill: $color-main;
                        }
                    }
                }

                // Search button
                #co-search {
                    input[type="submit"] {
                        position: absolute;
                        right: 15px;
                        float: none;
                        @include size($search-input-button-size);
                        mask-image: url($urlShort + 'ic-misc-search.svg');
                        mask-position: center;
                        mask-size: contain;
                        mask-repeat: no-repeat;
                        background-color: $color-text;
                        text-indent: -9999px;
                        border-radius: $border-radius;
                        padding: 0;
                        transition: {
                            property: background-color;
                            duration: $duration;
                            timing-function: $timing;
                        }
        
                        &:hover, &:focus {
                            background-color: $color-text
                        }
                    }
                }
            }


            // Main
            #co-page {
                
                // Title
                h1, h2, h3 {
                    color: $co-color-title;
                }
                h1 {
                    margin: 0 0 10px;
                    padding-bottom: 5px;
                    border-bottom: $border-width $border-style;
                    font-size: $font-size--2;
                    font-weight: $font-weight--heading;
                }
                h2 {
                    margin: 0 0 15px;
                    padding-bottom: 5px;
                    border-bottom: $border-width $border-style;
                    font-size: $font-size--2;
                }

                // Subtitle
                .sous-theme h2, .sous-dossier h2 {
                    font-size: $font-size--3;
                }

                // Lists
                ul, ul.list-puce, ul.list-arrow {
                    li {                        
                        &::before {
                            content: "•";
                            position: absolute;
                            top: 50%;
                            left: 0;
                            color: $co-list-style;
                            font-size: 1.875rem; // 30px
                            line-height: 0;
                        }
                    }
                }

                // Card Title
                .fiche-bloc {
                    h1, h2, h3 {
                        color: $color-text;
                    }
                }

                // Card specific Title
                .bloc-serviceenligne-index {
                    h2 {
                        color: $color-text;
                    }
                }

                // Card on archive page (same style of .card)
                .co-home-theme {
                    .home-theme-list {
                        .home-theme-list-item {
                            padding: $card--padding;
                            border: 0;
                        }
                    }
                }

                // Button tool slide
                .tool-slide button {
                    color: $color-text;
                    transition: color $duration $timing;

                    > div {
                        background-color: $color-text;
                        transition: background-color $duration $timing;
                    }

                    &:hover {
                        color: $color-main;
                        transition: color $duration $timing;

                        > div {
                            background-color: $color-main;
                            transition: background-color $duration $timing;
                        }
                    }
                }

                // Table
                .table {
                    thead td {
                        color: $color-white;
                    }

                    caption {
                        color: $color-white;
                    }
                }

                // Tabs
                .tabs {
                    .nav-tabs > li {
                        &.active > span {
                            color: $color-white;
                        }
                        
                        > span {
                            &:hover, &:focus {
                                color: $color-white;
                            }
                        }
                    }
                }

            }


        }
    }
}
