.card {

    &__container {
        // Events v1
        &--event-v1 {
            gap: $card-event-v1-gap;
            padding: $card--padding;

            .card__content {
                padding: $card-content--padding;
            }
        }
    }


    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        padding: 20px 0;
        background-color: $card-date-v1--bg;
        margin: 20px 0 20px 20px;
        border-radius: $btn-border-radius;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 130px;
            padding: 0 20px;
            color: $card-date--color;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_day {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: toRem(25);
            line-height: toRem(30);
        }

        &_month {
            font-family: $font-family--heading;
            font-size: $font-size--text;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $card-date--color;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.events,
        &.events_category {
            background-image: url($urlSpriteImage + '/events-icons/events.svg');
        }
    }


    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {

            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 10px;
                left: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-right: 0;
                padding: 10px 20px;
                background-color: $card-date-v2--bg;

                p {
                    width: unset;
                    color: $card-date--color;
                    width: unset;
                    flex-direction: row;
                    gap: 5px;
                    padding: 0;
                }

                &_day, &_month {
                    font-size: toRem(16);
                    line-height: toRem(25);
                    font-weight: $font-weight-semibold;
                }

                svg {
                    transform: rotate(-90deg);
                    box-sizing: inherit;
                    padding: 0;
                    fill: $card-date--color;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;
            }

        }

        &__container--event-v1 {
            .card__date {
                padding: 20px 5px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .card  {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                padding: 10px 20px;
                margin: 0 0 20px;

                &__many {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                }

                &_day, &_month {
                    font-size: toRem(16);
                    line-height: toRem(25);
                    font-weight: $font-weight-semibold;
                }

                p {
                    width: unset;
                    padding: 0;
                    flex-direction: row;
                    gap: 0 5px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }
    }

}
