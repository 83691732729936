.breadcrumb {
    margin: 30px 0 40px;

    ul {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        gap: 10px 0;
        padding: 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text;
        line-height: 1.75;

        a {
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            transition: {
                property: background-color, border-color, color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                color: $color-text;
                text-decoration-color: $color-text;
                text-decoration: underline;
            }
        }
    }

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        fill: $color-text;
        vertical-align: text-top;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
