header {
    position: relative;
    z-index: 15;
    background-color: $color-main--fixed;
    padding: 30px 0;

    &.header {
        
        &.header--fixed {
            position: fixed;
            z-index: 15;
            top: 0;
            left: 0;
            right: 0;
            padding: 20px 0;
            background-color: $color-main--fixed;
            border-bottom: $border-width $border-style $color-white--fixed;
            transition: top $duration $timing;
    
            &.header--up {
                top: 0;
            }
    
            &.header--down {
                top: -111px;
            }

            & ~ #content {
                margin-top: 145px;
            }

            .identity {
                height: 60px;
                margin: 0;

                &__title {
                    height: 60px;
                
                    svg {
                        @include size(180px, 60px);
                    }
                }
            }

            .header {
                &__right {
                    margin-top: 0;
                }
            }
        }
    }

    .header {

        &__container {
            @include flex($direction: row, $justifyContent: space-between);
        }

        &__right {
            @include flex($direction: row, $alignItems: center, $justifyContent: flex-end);
            gap: 40px;
            margin-top: 5px;
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content, 80px);
    color: $color-text;
    margin-top: 5px;
    transition: color $duration $timing;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        height: 80px;
        margin: 0;
        padding: 0;
        font-family: $font-family--heading;
        font-size: $font-size--1;
        font-weight: $font-weight--heading;
        line-height: $line-height--heading;
        text-transform: uppercase;

        &:before {
            content: none;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        gap: 10px;
        background-color: $color-bg--transparent;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        border: 0;
        background-color: $color-white--fixed;
        border-radius: $btn-border-radius;
        cursor: pointer;
        transition: background-color $duration $timing;

        svg {
            @include size(40px);
            fill: $color-dark--fixed;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-third;

            svg {
                fill: $color-dark--fixed;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    }

    &__item-mode {
        position: relative;
        @include size(40px);
        background-color: $color-white--fixed;
        border-radius: $btn-border-radius;
        transition: {
            property: background-color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-third;

            svg {
                fill: $color-dark--fixed;
            }
        }

        input {
            position: relative;
            z-index: 1;
            opacity: 0;
            @include size(40px);
            
            &:hover {
                cursor: pointer;
            }

            &:checked {
                .tools__item-mode--dark {
                    opacity: 1;
                }
            }
        }

        &--light, &--dark {
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            background-color: $color-bg--transparent;
            transition: {
                property: background-color, opacity;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &--dark {
            opacity: 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
// @media screen and (max-width: $large) {
// }


// 960
@media screen and (max-width: $medium) {

}


// 800
@media screen and (max-width: 800px) {

    header {

        .header {
            &__container {
                justify-content: center;
            }

            &__right {
                position: fixed;
                width: 100%;
                bottom: 0;
                background: $color-main--fixed;
                border-top: $border-width $border-style $color-white--fixed;
                left: 0;
                right: 0;
                justify-content: space-between;
                padding: 20px calc(5% + 10px);
                margin: 0;
            }
        }
    }
    .tools__translate__wrapper {
        bottom: 55px;
        top: unset;

        &:before {
            content: none;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    header {

        .header {
            &__right {
                gap: 15px;
            }
        }
    }

    .tools {

        &__content {
            gap: 5px;
        }
    }
}
