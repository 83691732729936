.home {

    &__title {
        position: relative;
        font-size: toRem(50);
        line-height: toRem(55);
        font-weight: $font-weight--heading;
        color: $color-main;
        padding: 0 0 0 25px;
        margin: 0 0 20px;

        &:before {
            content: '';
            @include absolute($top: 0, $bottom: 0, $left: 0);
            @include size(15px);
            background-color: $color-fourth;
            border-radius: $border-radius--round;
            margin: auto 0;
        }
    }
    
    &__navigation {
        position: relative;
        @include flex($justifyContent: space-between);
        @include size(90px, 40px);
        margin-left: auto;
        margin-top: -65px;
        margin-bottom: 25px;

        .swiper-button {
            position: initial;
            @include size(40px);
            border: 0;
            padding: 0;
            margin: 0;
            border-radius: $btn-border-radius;
            background-color: $color-main;
            transition: {
                property: all;
                duration: $duration;
                timing-function: $timing;
            }

            &:before, &:after {
                content: none;
            }

            svg {
                @include size(40px);
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-dark--fixed;
                }
            }
        }
    }
}

.js-postsNav--no-slider, .js-eventsNav--no-slider {
    display: none;
}


//======================================================================================================
// Newsthread (Fil d'infos)
//======================================================================================================
.newsthread {
    background-color: $color-btn;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
    }

    &__title {
        display: flex;
        align-items: center;

        svg {
            height: 30px;
            width: 30px;
            margin-right: 15px;
            fill: $color-text--fixed;
        }

        &__text {
            font-size: $font-size--4;
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            color: $color-text--fixed;
            width: 100px;
        }
    }

    &__link {
        color: $color-text--fixed;

        &__more {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-decoration: underline;
            color: $color-text--fixed;
            margin-left: 30px;
            transition: $duration;
        }

        &:hover {
            .newsthread {
                &__link {
                    &__more {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__slider {
        width: 100%;
        margin-bottom: -5px;
    }

    &__navigation {
        margin: auto 0;
        gap: 10px;
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &__shape {
        z-index: -1;
        @include absolute($top: 0, $right: 0, $left: 0);
        @include size(100%, auto);
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            @include absolute(0, 0, 0, 0);
            height: 100%;
            pointer-events: none;
        }
    }

    .slideshow {
        @include size(100%, 350px);
        border-radius: $border-radius 50px $border-radius $border-radius;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: center;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination__container {
            @include size(fit-content, 25px);
            padding: 0 15px;
            @include absolute($bottom: 20px, $right: 20px);
            @include flex($alignItems: center, $justifyContent: center);
            gap: 10px;
            border-radius: 15px;
            z-index: 2;
            width: auto;
            overflow: hidden;

            &:before {
                content: "";
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                backdrop-filter: blur(2.5px);
                background-color: rgba($color-main--fixed, .75);
            }
    
            .swiper-pagination-bullet {
                position: relative;
                z-index: 1;
                @include size(12px);
                margin: 0 10px 0 0;
                background-color: $color-white--fixed;
                border: $border-width $border-style $color-white--fixed;
                border-radius: $border-radius--round;
                opacity: 1;
                pointer-events: auto;
                transition: background-color $duration $timing;
                box-sizing: border-box;
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                }
    
                &-active {
                    background-color: $color-bg--transparent;
                }

                &:last-child {
                    margin: 0;
                }
            }

            .nav-swiper-autoplay {
                position: relative;
                @include size(12px);
                border: 0;
                margin: 0;
                padding: 0;
                background: $color-bg--transparent;

                &__play, &__pause {
                    position: absolute;
                    @include size(12px);
                    fill: $color-white--fixed;
                    transition: opacity $duration $timing;
                }

                &--pause {
                    .nav-swiper-autoplay {
                        &__play {
                            opacity: 1;
                        }

                        &__pause {
                            opacity: 0;
                        }
                    }
                }

                &--play {
                    .nav-swiper-autoplay {
                        &__play {
                            opacity: 0;
                        }

                        &__pause {
                            opacity: 1;
                        }
                    }
                }
            }
        }    
    }
}


//======================================================================================================
// Search bar + Quick access
//======================================================================================================
.searchaccess {
    position: relative;
    z-index: 2;
    margin-bottom: 100px;

    &__container {
        @include flex($direction: row, $wrap: wrap, $alignItems: flex-end, $justifyContent: flex-end);
        align-content: flex-end;
        max-height: 180px;
        gap: 40px;
    }

    &__buttons {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        gap: 10px;
    }

    &__button {
        @include size(auto, 40px);
        margin: 0;
        padding: 10px 15px;
        color: $color-text;
        border-color: $color-second;
        background-color: $color-bg--transparent;
        text-transform: unset;
        
        &:hover, &:focus {
            color: $color-text--fixed;
            border-color: $color-second;
            background-color: $color-second;
        }
    }

    .searchbar-home {
        @include size(calc(50% - 65px), auto);
        background-color: $color-light;
        border-radius: $border-radius;
        margin-top: -45px;
        margin-left: auto;
        padding: 30px 40px 30px 30px;

        &__bar {
            position: relative;

            .searchResult {
                @include absolute($top: 39px, $right: 0, $left: 0);
                margin: 0 auto;

                li {
                    background-color: $color-white;
                
                    a {
                        font-size: toRem(14);
                        line-height: toRem(18);
                        padding: 15px;
                    }

                    .resultCPT {
                        font-size: toRem(14);
                        line-height: toRem(18);
                        right: 15px;
                    }
                }
            }
        }

        &__title {
            width: 250px;
            font-size: toRem(25);
            line-height: toRem(25);
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
            margin: 0 0 20px;
            letter-spacing: 5%;
        }

        .search-container {
            margin-bottom: 15px;

            &.search-container-open {
                #searchInput {
                    border-radius: $border-radius--input $border-radius--input 0 0;
                }
                
                .search-svg {
                    border-radius: $border-radius--input $border-radius--input 0 $border-radius--input;
                }
            }
            
            #searchInput {
                font-size: toRem(14);
                line-height: toRem(20);
                height: 40px;
                padding: 10px 60px 10px 15px;
                border-radius: $border-radius--input;
                background-color: $color-white
            }

            .search-svg {
                @include size(38px);
                border-radius: $border-radius--input;
                background-color: $color-third;
                border-color: $color-third;
                border: 0;
                right: 1px;

                svg {
                    @include size(40px);
                    fill: $color-text--fixed;
                }
                
                &:hover, &:focus {
                    border-color: $color-dark;
                    background-color: $color-dark;
                    
                    svg {
                        fill: $color-white
                    }
                }
            }
        }
    }

    .access {
        @include size(calc(50% - 25px), auto);
        margin-bottom: 5px;
        
        &__list {
            @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
        }

        &__link {
            @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
            @include size(100px, 135px);
            gap: 10px;

            svg {
                @include size(85px);
                fill: $color-text;
                background-color: $color-light;
                border-radius: $border-radius--round;
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                
                svg {
                    fill: $color-white;
                    background-color: $color-main;
                }
            }
        }

        &__label {
            @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
            font-size: toRem(16);
            line-height: toRem(20);
            font-weight: $font-weight-normal;
            font-family: $font-family--heading;

            span {
                font-weight: $font-weight-bold;
            }
        }
    }
}


//======================================================================================================
// Posts
//======================================================================================================
.posts {

    &__section {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    &__item {
        position: relative;
        @include size(370px, 260px);
        max-width: 370px;
        border-radius: $border-radius;
        background-color: $color-light;
        overflow: hidden;

        &.swiper-slide {
            height: 260px;
        }

        &:before {
            content: "";
            z-index: 2;
            @include size(15px);
            @include absolute($top: 20px, $right: 20px);
            border-radius: $border-radius--round;
            background-color: $color-third;
            transition: {
                property: width, height;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {
            &:before {
                @include size(20px);
            }

            .posts {
                &__item {

                    &__title {
                        color: $color-white;
                    }

                    &__container-img {
                        img {
                            transform: scale(1.05);
                        }
                    }

                    &__category {
                        background-color: $color-third;
                    }
                }
            }
        }

        &__container-img {
            position: relative;
            @include size(100%);

            &:before {
                content: "";
                z-index: 1;
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
                border-radius: $border-radius;
                margin: auto;
            }

            &--notimg {

                & ~ .posts__item__content {
                    .posts__item__title {
                        color: $color-text;
                    }
                }

                &:before {
                    content: none;
                }

                svg {
                    @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                    margin: auto;
                    fill: $color-text;
                    @include size(110px);
                    opacity: .4;
                }
            }

            picture {
                display: block;
                @include size(100%);
                
                img {
                    display: block;
                    @include size(100%);
                    object-fit: cover;
                    object-position: center;
                    transform: scale(1);
                    transition: transform $duration $timing;
                }
            }
        }

        &__content {
            z-index: 1;
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-end);
            gap: 15px;
            padding: 30px;
            // background-color: $color-light;
            margin: auto;
            transition: {
                property: background-color;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &__category {
            font-size: toRem(14);
            line-height: toRem(20);
            font-weight: $font-weight-semibold;
            padding: 5px 15px;
            color: $color-text--fixed;
            background-color: $color-second;
            border-radius: $btn-border-radius;
            text-transform: uppercase;
            transition: background-color $duration $timing;
        }

        &__title {
            color: $color-white--fixed;
            font-size: toRem(25);
            line-height: toRem(30);
            font-weight: $font-weight-bold;
            margin: 0;
            transition: {
                property: color;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &__buttons {
        @include flex($direction: row, $justifyContent: flex-end);
        margin-top: 30px;
    }

    &__button {
        margin: 0;
    }
}


//======================================================================================================
// Events
//======================================================================================================
.events {

    &__section {
        position: relative;
        margin-top: 100px;
        margin-bottom: 100px;

        &:before {
            content: "";
            z-index: -1;
            @include size(100%, 1890px);
            @include absolute($top: 165px, $right: 0, $left: 0);
            background-image: url($urlSpriteImage + 'theme-icons/fond_page.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            margin: auto;
            opacity: .2;
        }

        .swiper-container {
            margin-top: -40px;
            overflow: unset;

            &:has(.events__item.swiper-slide-active.swiper-slide--odd) {
                .events__item.swiper-slide.swiper-slide--odd {
                    margin-top: 40px;
                    margin-bottom: 0;
                }
                .events__item.swiper-slide.swiper-slide--even {
                    margin-top: 0;
                    margin-bottom: 40px;
                }
            }

            &:has(.events__item.swiper-slide-active.swiper-slide--even) {
                .events__item.swiper-slide.swiper-slide--odd {
                    margin-top: 0;
                    margin-bottom: 40px;
                }
                .events__item.swiper-slide.swiper-slide--even {
                    margin-top: 40px;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__item {
        position: relative;
        @include flex($direction: column, $justifyContent: flex-end);
        @include size(370px, 300px);
        border-radius: $border-radius;
        background-color: $color-light;
        overflow: hidden;
        max-width: 370px;
        margin-right: 30px;

        &.swiper-slide {
            height: 300px;
            margin-top: 40px;
            margin-bottom: 0;
            transition: {
                property: margin, opacity;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &.swiper-slide-active {

            &.swiper-slide--odd {
                & ~ .swiper-slide--odd {
                    margin-top: 40px;
                    margin-bottom: 0;
                }

                & ~ .swiper-slide--even {
                    margin-top: 0;
                    margin-bottom: 40px;
                }
            }

            &.swiper-slide--even {
                & ~ .swiper-slide--odd {
                    margin-top: 0;
                    margin-bottom: 40px;
                }

                & ~ .swiper-slide--even {
                    margin-top: 40px;
                    margin-bottom: 0;
                }
            }
        }
        

        &:hover, &:focus {
            &.card--square .events__item__date.card__date p span {
                color: $color-white;
            }

            .events__item {
                &__content {
                    &::before {
                    background-color: $color-main;
                    }
                }

                &__title {
                    color: $color-white;
                }

                &__shape {
                    path[ fill="#E9F1F2"] {
                        fill: $color-main;
                    }
                }
            }
        }

        &__container-img {
            @include flex($alignItems: center, $justifyContent: center);
            @include size(100%, 210px);

            svg {
                fill: $color-text;
                @include size(110px);
                opacity: .4;
            }

            picture {
                display: block;
                @include absolute($top: 0, $right: 0, $left: 0);
                @include size(100%, 210px);
                margin: 0 auto;
                
                img {
                    display: block;
                    @include size(100%);
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        &__content {
            position: relative;
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-end);
            padding: 0 25px 25px;
            gap: 10px;

            &:before {
                content: '';
                z-index: 1;
                @include size(100%, 65px);
                @include absolute($right: 0, $bottom: 0, $left: 0);
                background-color: $color-light;
                margin: 0 auto;
                transition: {
                    property: background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__shape {
            @include absolute($top: -55px, $right: 0, $left: 0);
            @include size(100%, auto);
            margin: 0 auto;

            path[ fill="#E9F1F2"] {
                fill: $color-light;
                transition: {
                    property: fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &.card--square .events__item__date.card__date {
            position: relative;
            z-index: 1;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            gap: 5px;
            background-color: $color-bg--transparent;

            p span {
                color: $color-text;
                font-weight: $font-weight-normal;
                transition: {
                    property: color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__title {
            position: relative;
            color: $color-text;
            z-index: 1;
            margin: 0;
            transition: {
                property: color;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &__buttons {
        @include flex($direction: row, $justifyContent: flex-end);
        margin-top: 30px;
    }

    &__button {
        margin: 0;
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        margin: 100px 0;

        &__title {
            display: inline-block;
            font-size: toRem(40);
            line-height: toRem(50);
            padding: 0 20px;
            margin: 0;
            
            &:before, &:after {
                @include size(10px);
            }

            &:after {
                content: "";
                @include absolute($top: 0, $right: 0, $bottom: 0);
                background-color: $color-fourth;
                border-radius: $border-radius--round;
                margin: auto 0;
            }
        }
    }

    &__container {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
    }

    &__content {
        position: relative;
        z-index: 1;
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        gap: 20px;
        @include size(calc(50% - 95px), 440px);
        padding: 50px;
        background-color: $color-light;
        border-radius: $border-radius;
        overflow: hidden;

        &__swiper {
            width: 100%;
        }

        &__item {
            @include flex($direction: column, $alignItems: center, $justifyContent: flex-end);
        }
    }

    &__title {
        position: relative;
        z-index: 1;
        font-size: toRem(30);
        line-height: toRem(35);
        margin: 0 0 35px;
        text-align: center;
        height: 70px;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    &__shape {
        @include absolute($right: 0, $top: 0, $left: 0);
        @include size(100%, 335px);
        margin: 0 auto;
        fill: $color-white;
        opacity: .5;
    }

    &__buttons {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: center);
        gap: 30px;
    }

    &__button {
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        font-weight: $font-weight-bold;
        color: $color-text;
        gap: 10px;

        svg {
            @include size(40px);
            fill: $color-text--fixed;
            background-color: $color-third;
            border-radius: $btn-border-radius;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {
            svg {
                fill: $color-white;
                background-color: $color-dark;
            }
        }
    }

    &__allbutton {
        position: relative;
        z-index: 1;
        background-color: $color-white;
        border-color: $color-white;
        color: $color-text;
        margin: 20px auto 0;

        &:hover, &:focus {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;
        }
    }

    &__navigation {
        @include size(40px, 90px);
        flex-direction: column;
        margin: 0;
    }

    &__image {
        @include size(calc(50% - 15px), 420px);
        overflow: hidden;

        &__swiper.swiper-container {
            @include size(285px, 420px);
            margin-left: 0;
            overflow: unset;
            perspective: 1045px;
            padding: 15px 0;

            .swiper-wraper {
                transform-style: unset!important;
            }

            .kiosque {
                &__item {
                    overflow: hidden;
                    border-radius: $border-radius;
                    box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.10);
                    background-color: $color-light;

                    a {
                        display: block;
                        @include size(100%);
                    }

                    &__image {
                        display: block;
                        @include size(100%);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }

                        &--no-image {
                            position: relative;

                            svg {
                                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                                @include size(110px);
                                fill: $color-text;
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Socials
//======================================================================================================
.socials {

    &__section {
        margin: 120px 0 100px;
    }

    &__container {
        @include flex($direction: row, $alignItems: center, $justifyContent: center);
        gap: 30px;
    }

    &__wall {
        @include size(calc(50% + 145px), 380px);

        #ff-stream-1 {
            @include size(100%);
            min-height: unset!important;
            padding: 0;
            margin: 0;
            background-color: unset!important;

            .ff-stream-wrapper {
                width: calc(100% + 60px);
                margin: 0 0 0 -30px;
                padding: 0;
                height: 100% !important;
                
                .ff-item {
                    height: 100%!important;
                    border-radius: $border-radius!important;
                    overflow: hidden !important;

                    .picture-item__inner {
                        height: 380px!important;
                        background-color: $color-light;

                        &:before {
                            background: rgba(0,0,0,.025);
                            transition: background $duration $timing;
                        }

                        &:hover {
                            &:before {
                                background: rgba(0,0,0,.05);
                            }
                        }

                        .ff-item-cont {
                            height: 340px!important;

                            .ff-item-meta, .ff-carousel-icon {
                                display: none!important;
                            }

                            .ff-img-holder {
                                height: 200px!important;
                            }

                            .ff-content {
                                height: 140px!important;
                                margin: 0!important;
                                padding: 20px!important;
                                color: $color-text!important;
                            }

                            .ff-label-wrapper {
                                display: none;
                            }
                        }

                        .ff-item-bar {
                            height: 40px!important;
                            border-top-color: $color-white!important;

                            a, div {
                                color: $color-text!important;
                            }
                        }
                    }
                }
            }

            .ff-loadmore-wrapper {
                display: none!important;
            }
        }
    }

    &__content {
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-start);
        gap: 30px;
        padding-top: 40px;
        @include size(calc(50% - 175px), 380px);
        background-color: $color-main;
        border-radius: $border-radius;
    }

    &__title {
        font-size: toRem(35);
        line-height: toRem(40);
        margin: 0;
        padding: 0 30px 0 0;
        margin: 5px 40px 0;
        color: $color-white--fixed;

        &::before {
            @include size(10px);
            top: unset;
            left: unset;
            right: 0;
            bottom: 10px;
        }
    }

    &__list {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
        gap: 15px;
        width: 100%;
        padding: 40px 40px 45px;
        border-radius: $border-radius 0 $border-radius $border-radius;
        background-color: $color-bg-dark--fixed;
    }

    &__network {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        gap: 15px;

        &__link {
            display: inline-block;
            @include size(50px);
            background-color: rgba($color-white--fixed, .15);
            border: $border-width $border-style $color-white--fixed;
            border-radius: $btn-border-radius;
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size(48px);
                fill: $color-white--fixed;
                transition: {
                    property: fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                background-color: $color-white--fixed;
                border-color: $color-white--fixed;

                svg {
                    fill: $color-text--fixed;
                }
            }
        }
    }

    &__buttons {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
        gap: 15px;
        width: 100%;
    }

    &__button {
        width: 100%;
        column-gap: 10px;
        color: $color-white--fixed;
        background-color: $color-bg--transparent;
        border-color: $color-third;
        padding: 0;
        margin: 0;

        svg {
            @include size(20px);
            fill: $color-white--fixed;
        }

        &:hover, &:focus {
            border-color: $color-third;
            background-color: $color-third;
            color: $color-text--fixed;

            svg {
                fill: $color-text--fixed;
            }
        }
    }
}


//======================================================================================================
// Grands Projets
//======================================================================================================
.project {

    &__section {
        margin: 150px 0 120px;
    }

    &__navigation {
        margin-top: -125px;
    }

    &__item {
        @include flex($direction: row, $wrap: wrap, $alignItems: flex-end, $justifyContent: center);

        picture {
            display: block;
            @include size(calc(50% + 65px), 350px);
            margin-bottom: 70px;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                display: block;
                @include size(100%);
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &__content {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: center);
        z-index: 1;
        @include size(50%, 345px);
        margin-right: -65px;
        box-sizing: border-box;
        background-color: $color-light;
        border-radius: $border-radius;
        padding: 50px;
    }

    &__name {
        font-size: toRem(35);
        line-height: toRem(40);
        color: $color-text;
        margin: 0 0 10px;
    }

    &__desc {
        font-size: toRem(16);
        line-height: toRem(25);
    }

    &__button {
        margin: 30px 0 0;
    }
}


//======================================================================================================
// Retour en images
//======================================================================================================
.retourimages {

    &__section {
        margin: 100px 0 120px;
    }

    &__title {
        br {
            display: none;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 30px;
        height: 630px;
    }
    
    &__item {
        border-radius: $border-radius;
        overflow: hidden;

        &__title {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            margin: 0 20px;
            display: inline-block;
            background-color: $color-main;
            padding: 10px;
            max-width: calc(100% - 40px);
            bottom: 20px;
            color: $color-white;
            font-size: toRem(18);
            font-weight: $font-weight-normal;
            border-radius: 10px;
            width: fit-content;
        }

        &:nth-child(1) {
            grid-column: 1;
            grid-row: 1 / 3;
            border-top-left-radius: 50px;
        }

        &:nth-child(2) {
            grid-column: 2;
            grid-row: 1 / 2;
        }

        &:nth-child(3) {
            grid-column: 3;
            grid-row: 1 / 2;
        }

        &:nth-child(4) {
            grid-column: 2 / 4;
            grid-row: 2;
            border-bottom-right-radius: 50px;

            .retourimages__item__link {
                .retourimages__btn {
                    top: 20px;
                    bottom: unset;
                }
            }
        }

        &__link {
            display: inline-block;
            position: relative;
            @include size(100%);

            picture {
                display: block;
                @include size(100%);
            
                img {
                    display: block;
                    @include size(100%);
                    object-fit: cover;
                    object-position: center;
                }
            }

            &:hover, &:focus {

                .retourimages__btn {
                    fill: $color-text--fixed;
                    background-color: $color-white--fixed;
                    border-color: $color-white--fixed;
                }
            }
        }
    }

    &__btn {
        @include absolute($top: 20px, $right: 20px);
        @include size(35px);
        fill: $color-text--fixed;
        border: $border-width $border-style $color-white--fixed;
        background-color: rgba($color-white--fixed, .5);
        border-radius: $border-radius--round;
        transition: {
            property: background-color, border-color, fill;
            duration: $duration;
            timing-function: $timing;
        }

        &--externe {
            padding: 5px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {    
        &:before {
            background-position: bottom 1380px center;
        }
    }

    // Fil infos
    .newsthread {
        &__slider {
            width: calc(100% - 235px);
        }
    }
    
    // Alu
    .home-slideshow {

        .slideshow {
            height: 300px;
            border-radius: 5px 50px 5px 5px;
        }
    }

    // Search bar + Access
    .searchaccess {

        .searchbar-home {
            width: calc(50% - 40px);
            padding: 30px 20px;

            &__title {
                margin-bottom: 15px;
            }
        }

        &__buttons {
            gap: 10px 15px;
        }

        &__button {
            font-size: toRem(14);
        }

        .access {
            width: calc(50% - 30px);
            margin: 0;

            &__label {
                font-size: toRem(14);
                line-height: toRem(18);
            }
        }
    }

    // Post
    .posts {

        &__item {
            @include size(300px, 230px);
            margin-right: 20px;
            max-width: 300px;
            
            &.swiper-slide {
                height: 230px;
            }

            &__content {
                padding: 25px;
            }

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
            }
        }
    }

    // Events
    .events {

        &__section {
            &:before {
                top: 150px;
                height: 1180px;
            }
        }
        
        &__item {
            @include size(300px, 255px);
            margin-right: 20px;
            max-width: 300px;

            &.swiper-slide {
                height: 255px;
            }

            &__container-img {
                picture {
                    height: 180px;
                }
            }

            &__shape {
                top: -40px;
            }

            &__content {
                &:before {
                    height: 35px;
                }
            }

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
            }

            &.card--square .events__item__date.card__date p span {
                font-size: toRem(15);
                line-height: toRem(20);
            }
        }
    }

    // Kiosque
    .kiosque {

        &__content {
            @include size(calc(50% - 30px), 440px);
        }

        &__title {
            font-size: toRem(25);
            line-height: toRem(35);
        }

        &__image {
            @include size(calc(50% - 50px), 420px);
        }
    }

    // Socials
    .socials {

        &__section {
            margin-top: 100px;
            margin-bottom: 140px;
        }

        &__container {
            gap: 20px;
        }

        &__wall {
            @include size(calc(50% + 110px), 360px);

            #ff-stream-1 {
                .ff-stream-wrapper {
                    margin: 0 0 0 -20px;
                    width: calc(100% + 40px);

                    .ff-item {
                        .picture-item__inner {
                            height: 360px!important;

                            .ff-item-cont {
                                height: 320px!important;

                                .ff-img-holder {
                                    height: 180px!important;

                                    img {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__content {
            padding-top: 35px;
            gap: 40px;
        }

        &__title {
            margin: 0 30px;
        }
        
        &__list {
            padding: 30px 30px 35px;
        }

        &__content {
            @include size(calc(50% - 130px), 360px);
        }

        &__button {
            font-size: toRem(14);
            line-height: toRem(20);
        }
    }

    // Project 
    .project {
        margin-top: 140px;
        margin-bottom: 100px;

        &__item {
            picture {
                width: calc(50% + 40px);
                height: 300px;
                margin-bottom: 50px;
            }
        }

        &__content {
            padding: 40px 35px;
            margin-right: -40px;
            height: 280px;
        }

        &__name {
            font-size: toRem(28);
            line-height: toRem(30);
        }

        &__desc {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        &__button {
            margin-top: 20px;
        }
    }

    // Retours images
    .retourimages {

        &__list {
            height: 505px;
            grid-gap: 20px;
        }

        &__item {
            
            &:nth-child(4) {
                .retourimages {
                    &__item {
                        &__link {
                            .retourimages__btn {
                                bottom: unset;
                                top: 15px;
                            }
                        }
                    }
                }
            }
        }

        &__btn {
            bottom: 15px;
            right: 15px;
        }


    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {    
        &:before {
            background-position: bottom 2440px center;
        }
    }

    

    // Fil infos
    .newsthread {
        &__slider {
            width: calc(100% - 135px);
        }

        &__title {
            &__text {
                display: none;
            }
        }

        &__link {
            &__more {
                margin-left: 10px;
            }
        }
    }

    // Alu
    .home-slideshow {
        position: relative;

        .slideshow {

            &__pagination {
                &__container {
                    bottom: 60px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: fit-content;
                }
            }

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }

    // Search bar + Access
    .searchaccess {

        &__container {
            max-height: unset;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .searchbar-home {
            width: calc(100% - 90px);
            padding: 30px 20px;
        }

        .access {
            width: calc(100% - 90px);
            margin: 0 auto;
        }
    }

    // Post
    .posts {

        &__item {
            @include size(calc(50% - 10px), 230px);
            max-width: calc(50% - 10px);

            &__container-img--notimg {
                & ~ .posts__item__content {
                    .posts__item__title {
                        color: $color-text;
                    }
                }
            }
        
            &__content {
                background-color: $color-bg--transparent;
            }

            &__title {
                color: $color-white--fixed;
            }
        }
    }

    // Events
    .events {

        &__section {
            &:before {
                top: 125px;
                height: 950px;
            }

            .swiper-container {
                margin: 0;
                
                &:has(.events__item.swiper-slide-active.swiper-slide--odd) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
    
                &:has(.events__item.swiper-slide-active.swiper-slide--even) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__item {
            @include size(calc(50% - 10px), 255px);
            max-width: calc(50% - 10px);
            margin-top: 0!important;
            margin-bottom: 0!important;
        }

        &__image {
            @include size(200px, 275px);
        }
    }

    // Kiosque
    .kiosque {

        &__container {
            justify-content: flex-start;
            gap: 20px;
        }

        &__content {
            z-index: 2;
            @include size(50%, 370px);
            padding: 20px;
        }

        &__image {
            @include size(270px, 370px);
            margin-right: auto;
            overflow: unset;

            &__swiper{
                &.swiper-container {
                    @include size(270px, 370px);
                    padding: 0;

                    .swiper-slide {
                        opacity: 0;
                        box-shadow: $shadow;
                        transition-property: all!important;
                        pointer-events: none;
                    }

                    .swiper-slide-active {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }

        &__title {
            margin-bottom: 20px;
        }

        &__buttons {
            gap: 15px;
        }

        &__button {
            font-size: 0;
            line-height: 0;
            @include size(40px);
        }
    }

    // Socials
    .socials {
        
        &__section {
            margin: 100px 0;
        }

        &__container {
            flex-direction: column;
            gap: 30px;
        }

        &__wall {
            width: 100%;
        }

        &__content {
            width: 100%;
            height: auto;
        }

        &__buttons {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__button {
            @include size(280px, 50px);
            padding: 0 15px;
        }
    }

    // Project
    .project {

        &__section {
            margin-top: 100px;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__navigation {
            margin-top: -60px;
            margin-bottom: 20px;
        }

        &__item {
            flex-direction: column;
        
            picture {
                @include size(100%, 300px);
                margin-bottom: 0;
            }
        }

        &__content {
            width: calc(100% - 80px);
            height: auto;
            margin: -80px auto 0;
            order: 1;
            padding: 30px 25px;
        }
    }

    // Retours images
    .retourimages {
        &__section {
            margin: 100px 0;
        }

        &__list {
            height: 770px;
            grid-template-columns: calc(50% - 50px) 1fr;
            grid-template-rows: 1fr 1fr 240px;
        }

        &__item {
            &:nth-child(1) {
                grid-column: 1;
                grid-row: 1/3;
            }
            &:nth-child(2) {
                grid-column: 2;
                grid-row: 1/2;
            }
            &:nth-child(3) {
                grid-column: 2;
                grid-row: 2/3;
            }
            &:nth-child(4) {
                grid-column: 1/3;
                grid-row: 3;
            }
        }
    }
}


// 800
@media screen and (max-width: 800px) {
    

    // Posts
    .posts {

        &__section {
            margin: 80px 0;

            .swiper-container {
                width: 100%;
            }
        }

        &__container {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 20px;
        }

        &__title {
            margin: 0;
        }

        &__navigation {
            margin: 0;
            order: 1;
        }

        &__buttons {
            margin: 0;
            order: 1;
            justify-content: flex-start;
        }

        &__item {
            &.swiper-slide {
                width: 100%;
            }
        }
    }

    // Events {
    .events {
        
        &__section {
            margin: 80px 0;

            .swiper-container {
                width: 100%;
            }
        }
        
        &__container {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 20px;
        }

        &__title {
            margin: 0;
        }

        &__navigation {
            margin: 0;
            order: 1;
        }

        &__buttons {
            margin: 0;
            order: 1;
            justify-content: flex-start;
        }

        &__item {

            &__content {
                &:before {
                    height: 0;
                }
            }

            &__shape {
                top: -40px;
            }
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            position: relative;
            padding: 120px 0 50px;
            background-color: $color-light;
            
            &__title {
                @include absolute($top: 50px, $right: 0, $left: 0);
                margin: 0 auto;
                width: fit-content;
            }
        }

        &__container {
            flex-direction: column;
        }

        &__navigation {
            margin-top: -40px;
            order: 1;
            flex-direction: row;
            @include size(90px, 40px);
        }

        &__image {
            margin: 0 auto;
            z-index: 3;
            @include size(200px, 275px);

            &__swiper {
                &.swiper-container {
                    @include size(200px, 275px);
                }
            }
        }

        &__content {
            @include size(300px, 210px);
            position: unset;
            order: 2;
            padding: 0;
            gap: 0;
            background-color: $color-bg--transparent;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__shape {
            width: 100%;
            height: 100%;
        }

        &__allbutton {
            margin-top: 20px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {    
        &:before {
            background-position: bottom 3570px center;
        }
    }

    // Newsthread
    .newsthread {
        &__content {
            flex-direction: column;
            height: 205px;
        }

        &__title {
            svg {
                margin: 20px 0 10px;
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &__more {
                margin: 10px 0;
            }
        }

        &__navigation {
            margin-bottom: 20px;
        }
    }

    .home {

        &__title {
            font-size: toRem(38);
            line-height: toRem(45);
        }
    }

    // Alu
    .home-slideshow {
        
        .container {
            margin: 0;
            padding: 0;
            max-width: unset;
        }

        .slideshow {
            height: 200px;
            border-radius: 0;

            &__pagination {
                &__container {
                    bottom: 40px;
                }
            }

            &__shape {
                display: none;
            }
            
            &__content {
                left: 30px;
                right: 30px;
                width: auto;
            }
        }
    }
    
    // Search bar + Access
    .searchaccess {
        margin-bottom: 90px;

        &__container {
            max-height: unset;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            gap: 20px;
        }

        .searchbar-home {
            width: 100%;
            padding: 30px 20px;
            margin-top: -25px;

            &__title {
                font-size: toRem(18);
                line-height: toRem(20);
                width: 200px;
            }
        }

        .access {
            width: 100%;
            margin: 0 auto;

            &__list {
                max-width: 400px;
                width: 100%;
            }

            &__link {
                @include size(70px, 95px);

                svg {
                    @include size(55px);
                }
            }

            &__label {
                font-size: toRem(12);
                line-height: toRem(15);
            }
        }
    }

    // Posts
    .posts {
        &__section {
            margin: 90px 0;
        }

        &__item.swiper-slide {
            max-width: 100%;
        }
    }

    // Events
    .events {

        &__section {
            margin: 90px 0 80px;

            &:before {
                top: 145px;
                height: 630px;
            }
        }

        &__item {
            &__shape {
                top: -70px;
            }

            &__content {
                &:before {
                    height: 30px;
                }
            }
            
            &.swiper-slide {
                max-width: 100%;
            }
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            margin: 80px 0;
        }
    }

    // Socials
    .socials {

        &__section {
            margin: 80px 0;
        }

        &__container {
            max-width: unset;
            padding: 0;
        }

        &__wall {
            max-width: 90%;
            padding: 0 10px;
        }

        &__content {
            border-radius: 0;
        }

        &__list {
            border-radius: $border-radius 0 0 0;
        }

        &__button {
            padding: 0 15px;
        }
    }

    // Project
    .project {

        &__section {
            margin: 80px 0;
        }

        &__container {
            display: flex;
            flex-direction: column;

            .swiper-container {
                width: 100%;
            }
        }

        &__item {
            picture {
                height: 200px;
            }
        }

        &__content {
            width: calc(100% - 40px);
            margin-top: -30px;
        }

        &__name {
            font-size: toRem(20);
            line-height: toRem(25);
        }

        &__navigation {
            order: 1;
            margin: 20px 0 0 20px;
        }
    }

    // retours images
    .retourimages {

        &__section {
            margin: 80px 0;
        }

        &__title {
            &:before {
                bottom: unset;
                margin: 0;
                top: 18px;
            }

            br {
                display: initial;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            height: unset;
        }

        &__item {
            &:nth-child(1) {
                @include size(100%, 285px);
            }
            &:nth-child(2) {
                @include size(100%, 245px);
            }
            &:nth-child(3) {
                @include size(100%, 245px);
            }
            &:nth-child(4) {
                @include size(100%, 190px);
            }
        }
    }
}


// 500
@media (max-width: 511px) {
    
    // Socials
    .socials {
        &__wall {
            height: 740px;

            #ff-stream-1 {
                height: 360px!important;
            }
        }
    }
}


// 500
@media (max-width: 500px) {
    
    // Events
    .events {
        &__item {
            &__shape {
                top: -40px;
            }
        }
    }
}


// 500
@media (max-width: 360px) {
    
    // Project
    .project__content {
        width: calc(100% - 20px);
    }
}