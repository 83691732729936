.cover {
    position: relative;

    &__shape {
        z-index: -1;
        @include absolute($top: 0, $right: 0, $left: 0);
        @include size(100%, auto);
    }

    &__container {
        @include flex($direction: row, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
    }

    &__informations {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: center);
        width: 100%;
        min-height: 215px;
        background-color: $color-light;
        border-radius: $border-radius;
        padding: 40px;
    }

    &__image-wrapper {
        width: calc(50% - 15px);
        height: 350px;
        background-color: $color-bg--image;
        border-radius: $border-radius 50px $border-radius $border-radius;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        & ~ .cover__informations {
            width: calc(50% - 15px);
            min-height: 350px;
        }
    }

    &__title {
        color: $color-text;
        margin: 0 0 15px;
        line-height: toRem(50);
    }

    &__lead-wrapper {
        p {
            margin: 0;
            font-weight: $font-weight-bold;
            font-size: toRem(18);
            line-height: toRem(25);
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 300px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {

        &__container {
            flex-direction: column;
            max-width: unset;
            padding: unset;
        }

        &__image-wrapper {
            width: 100%;
            border-radius: unset;

            & ~ .cover__informations {
                margin: -30px auto 0;
            }
        }

        &__informations {
            z-index: 1;
            padding: 30px;
            width: 100%!important;
            min-height: unset!important;
            max-width: calc(90% - 20px);
            margin: 0 auto;
        }

        &__title {
            line-height: toRem(36);
            padding-left: 20px;

            &:before {
                @include size(10px);
                top: 14px;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-size: toRem(16);
                line-height: toRem(20);
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
