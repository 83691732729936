html {
    // Light/Default theme variables
    --color-bg--body        : #FFFFFF;

    --color-main            : #093C35;

    --color-white           : #FFFFFF;
    --color-light           : #E9F1F2;
    --color-dark            : #0F1A12;

    --color-text            : #0F1A12;

    --input-border-focus    : rgba(9, 60, 53, .2);

    --card-border--hover    : rgba(9, 60, 53, .2);

    --box-shadow            : 0px 1px 0px rgba(0, 0, 0, 0.15);


    // Dark theme variables
    &[data-theme="dark"] {
      --color-bg--body        : #042B27;

      --color-main            : #E9F1F2;

      --color-white           : #0F1A12;
      --color-light           : #093C35;
      --color-dark            : #FFFFFF;
    
      --color-text            : #FFFFFF; 

      --input-border-focus    : rgba(233, 241, 242, .2);

      --card-border--hover    : rgba(233, 241, 242, .2);

      --box-shadow            : 0px 1px 0px rgba(255, 255, 255, 0.15);
    }
}